<template>
  <el-form
    ref="addressForm"
    label-position="top"
    :model="addressForm"
    :rules="addressFormRules"
  >
    <el-row :gutter="22">
      <el-col :span="12">
        <el-form-item label="First Name" prop="firstName">
          <el-input type="text" v-model="addressForm.firstName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Last Name" prop="lastName">
          <el-input type="text" v-model="addressForm.lastName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="Street Address" prop="street">
          <el-input type="text" v-model="addressForm.street"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="Apt, Suite, Etc." prop="houseNumber">
          <el-input type="text" v-model="addressForm.houseNumber"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" :sm="12" style="overflow: hidden">
        <el-form-item label="Country" prop="country">
          <el-select
            filterable
            placeholder=""
            v-model="addressForm.country"
            @change="countryChange"
            default-first-option
            style="width: 100%"
          >
            <el-option
              v-for="item in countryList"
              :key="item.dataLabel"
              :label="item.dataLabel"
              :value="item.dataLabel"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="24" :sm="12" :class="compState ? '' : 'dy-rule-ring'">
        <el-form-item label="State" prop="province">
          <el-select
            v-if="compState"
            placeholder=""
            v-model="addressForm.province"
            @change="stateChange"
            filterable
            style="width: 100%"
          >
            <!--@blur="onTypeBlur($event, 'province')"-->
            <el-option
              v-for="item in stateList"
              :key="item.dataValue"
              :label="item.dataLabel"
              :value="item.dataValue"
            >
            </el-option>
          </el-select>
          <el-input v-else type="text" v-model="addressForm.province"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" :sm="12">
        <el-form-item label="City" prop="city">
          <el-input type="text" v-model="addressForm.city"></el-input>
          <!--<el-select
            placeholder=""
            v-model="addressForm.city"
            filterable
            @change="onTypeChange($event, 'city')"
            style="width: 100%"
          >
            <el-option
              v-for="item in cityList"
              :key="item.dataValue"
              :label="item.dataLabel"
              :value="item.dataValue"
            >
            </el-option>
          </el-select>-->
        </el-form-item>
      </el-col>
      <el-col :span="24" :sm="12">
        <el-form-item label="Postal/Zip code" prop="postCode">
          <el-input type="text" v-model="addressForm.postCode"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="Phone" prop="phone">
          <div class="flex-flex-start-center">
            <el-input
              type="text"
              v-model="addressForm.countryCode"
              maxlength="4"
              class="phone-prepend"
            ></el-input>
            <el-input type="text" v-model="addressForm.phone"></el-input>
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="24" v-if="enableOperation">
        <el-form-item prop="isPrimal">
          <el-checkbox
            v-model="addressForm.isPrimal"
            :true-label="1"
            :false-label="0"
            >Set as default address</el-checkbox
          >
        </el-form-item>
      </el-col>
    </el-row>
    <div v-if="enableOperation">
      <button
        type="button"
        class="customer-button__submit"
        @click="saveAddress()"
      >
        save
      </button>
    </div>
  </el-form>
</template>
<script>
import service from '@/utils/request'
export default {
  name: 'AddressForm',
  props: {
    editForm: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    enableOperation: {
      type: Boolean,
      default: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateProvince = (rule, value, callback) => {
      if (value) {
        callback()
      }
      if (Array.isArray(this.stateList) && this.stateList.length>0) {
        callback(new Error('State is required!'))
      } else {
        callback()
      }
    }
    return {
      userId: '',
      isEditForm: false,
      addressForm: {
        firstName: '',
        lastName: '',
        street: '',
        houseNumber: '',
        country: '',
        province: '',
        city: '',
        postCode: '',
        phone: '',
        countryCode: '',
      },
      addressFormRules: {
        firstName: {
          required: true,
          trigger: 'blur',
          message: 'First Name is required!',
        },
        lastName: {
          required: true,
          trigger: 'blur',
          message: 'Last Name is required!',
        },
        street: {
          required: true,
          trigger: 'blur',
          message: 'Street Address is required!',
        },
        houseNumber: {
          required: true,
          trigger: 'blur',
          message: 'Apt, Suite, Etc. is required!',
        },
        country: {
          required: true,
          trigger: 'blur',
          message: 'Country is required!',
        },
        province: {
          required: true,
          validator: validateProvince,
          trigger: 'blur'
        },
        city: { required: true, trigger: 'blur', message: 'City is required!' },
        postCode: {
          required: true,
          trigger: 'blur',
          message: 'Postal/Zip code is required!',
        },
        phone: {
          required: true,
          trigger: 'blur',
          message: 'Phone is required!',
        },
      },

      countryList: [],
      stateList: [],
      cityList: [],
    }
  },
  computed: {
    compState() {
      return Array.isArray(this.stateList) && this.stateList.length>0
    }
  },
  watch: {
    editForm: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.addressForm = val
        } else {
          this.addressForm = {
            firstName: '',
            lastName: '',
            street: '',
            houseNumber: '',
            country: '',
            province: '',
            city: '',
            postCode: '',
            phone: '',
            countryCode: '',
          }
        }
      },
    },
    isEdit(val) {
      this.isEditForm = val
    },
    isVisible(val) {
      if (!val) {
        this.clearForm()
      }
    },
  },
  created() {
    if (this.enableOperation) {
      this.getUserId()
    }
    this.getCountryList()
  },

  methods: {
    getUserId() {
      service({
        url: `/user/info`,
        method: 'get',
      }).then((res) => {
        if (res.code === 200) {
          this.userId = res.data.id
        }
      })
    },
    saveAddress() {
      if (!this.userId) {
        this.$notify({
          title: 'Error',
          message: 'Cannot find current user!',
          type: 'error',
        })
        return
      }
      this.$refs.addressForm.validate((valid) => {
        if (!valid) return
        let url = `/user/addAddress`
        if (this.isEditForm) {
          url = `/user/updateAddress`
        }
        service({
          url,
          method: 'post',
          data: {
            userId: this.userId,
            ...this.addressForm,
          },
        }).then((res) => {
          if (res.code === 200) {
            this.$notify({
              title: 'Success',
              message: 'Operation success!',
              type: 'success',
            })
            // this.$message.success('success');
            this.$emit('saveAddressSuccess')
          }
        })
      })
    },
    getCountryList() {
      service({
        url: `/open/dict/country_code`,
        method: 'get',
      }).then((res) => {
        if (res.code === 200) {
          this.countryList = res.data || []
        }
      })
    },
    countryChange() {
      this.$set(this.addressForm, 'province', '')
      this.$set(this.addressForm, 'city', '')
      // this.addressForm.province = ''
      // this.addressForm.city = ''
      let countryOption = this.countryList.filter(
        (item) => item.dataLabel === this.addressForm.country
      )
      //console.log(countryOption)
      if (countryOption) {
        this.addressForm.countryCode = countryOption[0]?.dataValue
        this.$emit('selectName', countryOption[0]?.dataLabel)
      }
      service({
        url: `/open/dict/${this.addressForm.country}`,
        method: 'get',
      }).then((res) => {
        if (res.code === 200) {
          this.stateList = res.data || []
        }
      })
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.addressForm.validate((valid) => {
          resolve(valid)
        })
      })
    },
    stateChange(e) {
      this.$set(this.addressForm, 'province', e)
      this.$set(this.addressForm, 'city', '')
      // this.addressForm.city = ''
      this.$forceUpdate()
      service({
        url: `/open/dict/${this.addressForm.province}`,
        method: 'get',
      }).then((res) => {
        if (res.code === 200) {
          this.cityList = res.data || []
        }
      })
    },
    // onTypeBlur(e, k) {
    //   if (e.target.value.trim() !== '') {
    //     this.$set(this.addressForm, k, e.target.value)
    //   }
    // },
    onTypeChange(e, k) {
      this.$set(this.addressForm, k, e)
      this.$forceUpdate()
    },
    clearForm() {
      this.$refs.addressForm.clearValidate()
      this.$refs.addressForm.resetFields()
    },
  },
}
</script>
<style lang="scss" scoped>
.customer-button__submit {
  text-decoration: none;
  cursor: pointer;
  border-radius: 3px;
  transition: border-color 0.2s ease-out,
    box-shadow 225ms cubic-bezier(0.5, 0, 0.5, 1), color 0.2s ease-out;

  font-style: normal;
  font-weight: 200;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.8125rem;
  padding: 1.125rem 2rem;
  line-height: 1.4;
  color: #fff;
  background-color: #232c3f;
  border: 1px solid #232c3f;
  box-shadow: inset 0 0 #29344a;
  display: block;
  width: 100%;
  margin-bottom: 1.125rem;
  margin-top: 1rem;
}
.customer-button__submit:hover:not(:disabled),
.customer-button__submit:active {
  color: #fff;
  border: 1px solid #29344a;
  box-shadow: inset 0 -4rem #29344a;
}
.phone-prepend {
  max-width: 50px;
  ::v-deep input {
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    background-color: #f5f7fa;
  }
}
.dy-rule-ring {
  ::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
    content: ' ';
    width: 0;
    margin-right: 0;
  }
}

</style>

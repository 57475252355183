<template>
<div class="ProductOrder">

<!--  <el-scrollbar class="scrollbar-style">-->
    <el-row :gutter="0" class="scroll-row">
      <el-col :span="24" :sm="12" >

        <template v-if="images.length > 0">
          <ColumnsSwiper
            v-if="isPc"
            :data="images"
          ></ColumnsSwiper>

          <carousel
            v-else
            :starting-image="0"
            :images="images"
            :attr-name="attrName"
            :productInfo="productInfo"
          ></carousel>
        </template>

        <div v-else >
          <div style="width: 100%; height: 100px"></div>
          <!--<img src="@/assets/icon/No-Photo-Available.webp" width="100%" alt="" />-->
        </div>
      </el-col>
      <el-col :span="24" :sm="12" class="right-part" >

      <common-affix :target="isPc ? '.scroll-row' : 'document'" :offset="0" ref="AffixRef" style="width: 100%">
        <div class="product-detail">
          <div
            class="product-detail__header"
            @click="handleToProductDetail(productInfo)"
          >
            <h4 v-if="productInfo.subName">{{ productInfo.subName + ' ' + productInfo.name }}</h4>
            <h4 v-else>{{ productInfo.name }}</h4>
          </div>
          <!--<div class="product-detail__desc">
            <span v-if="productInfo.intro" v-html="productInfo.intro.replace(/\n/g, '<br>')"></span>
          </div>-->
          <div class="product-detail__price">
            <span class="cur-price" style="color: #de6d5c;"><CurrencySymbol :symbol="productInfo.symbol"/>{{ showSellingPrice | moneyFormat }}</span>
            <span class="original-price" v-if="productInfo.originalPrice"><CurrencySymbol :symbol="productInfo.symbol"/>{{ productInfo.originalPrice | moneyFormat }}</span>
          </div>
          <div class="product-detail__comment" v-if="productInfo.reviewsNum">
            <star-rating
              v-model="productInfo.score"
              :read-only="true"
              :increment="0.5"
              :show-rating="false"
              :star-size="20"
            ></star-rating>
            <p style="margin-left: 8px">
              {{ productInfo.reviewsNum ? productInfo.reviewsNum : 'No' }} reviews
            </p>
          </div>
          <div v-if="productInfo.attrs && productInfo.attrs.length > 0">
            <div
              v-for="attrItem in productInfo.attrs"
              :key="attrItem.attrCode"
              :class="
              attrItem.attrCode === 'Color'
                ? 'product-detail__color-pick'
                : 'product-detail__size-pick'
            "
            >
              <p class="aaa">{{ attrItem.attrCode }}: <span style="margin-left: 10px">{{ attrItem._curr }}</span></p>
              <div
                :class="
                attrItem.attrCode === 'Color'
                  ? 'product-detail__color-switch'
                  : 'product-detail__size-switch'
              "
              >
              <span
                :class="
                  attrItem.attrCode === 'Color' ? 'color-item' : 'size-item'
                "
                v-for="(detailItem, index) in attrItem.details"
                :key="detailItem.id"
              >
                <input
                  type="radio"
                  :name="attrItem.attrCode"
                  :id="detailItem.id"
                  @click="handleAttrClick(attrItem, detailItem)"
                />
                <el-image
                  v-if="detailItem.detailImg"
                  :for="detailItem.id"
                  @click="handleAttrClick(attrItem, detailItem, index)"
                  :class="[
                    'image-circle',
                    attrItem._curr == detailItem.detailName ? 'active' : '',
                    'imageSize',
                  ]"
                  :src="detailItem.detailImg"
                  fit="fill"
                ></el-image>
                <label
                  :for="detailItem.id"
                  v-else-if="attrItem.attrCode === 'Color'"
                  :class="['color-circle', detailItem.detailCode]"
                  :style="{ 'background-color': detailItem.detailCode }"
                >
                  {{ detailItem.detailName }}
                </label>
                <label v-else :for="detailItem.id" class="size-check">{{
                  detailItem.detailName
                }}</label>
              </span>
              </div>
            </div>
          </div>
          <div class="product-detail__quantity-pick" style="display: flex;align-items: center">
            <p style="margin-right: 20px">Quantity:</p>
            <el-input-number
              v-model="productCount"
              :min="1"
              :max="10"
              size="small"
            ></el-input-number>
          </div>

          <div class="product-detail__actions">
            <button
              class="product-detail__add-to-cart"
              @click="addToCart"
              :disabled="!checkedAllAttrs"
            >
              Add to cart
            </button>
            <button
              class="product-detail__buy"
              :disabled="!checkedAllAttrs"
              @click="quickOrder"
            >
              Buy it now
            </button>
          </div>

          <!-- 详情 -->
          <div class="detail-list">
            <div class="detail-item" @click="toMessage('Introduction')">
              <span>Introduction</span>
              <i class="el-icon-caret-right"></i>
            </div>
            <div class="detail-item" @click="toMessage('Reviews')">
              <span>
                Customer Reviews
                <label style="text-align: left" v-if="productInfo.reviewsNum">&nbsp;({{productInfo.reviewsNum}})</label>
              </span>
              <i class="el-icon-caret-right"></i>
            </div>
            <div class="detail-item" @click="toMessage('Claim Your Coupons')">
              <span>Claim Your Coupons</span>
              <i class="el-icon-caret-right"></i>
            </div>
            <div class="detail-item" @click="toMessage('Questions')">
              <span>Questions</span>
              <i class="el-icon-caret-right"></i>
            </div>
          </div>
          <!--div class="article-page__share">
            <p class="article-page__share-text">Share to:</p>
            <div class="share-buttons">
              <a
                href="javascript:void(0)"
                @click="twitterShare"
                class="share-buttons__twitter"
              >
                <svg
                  class="icon-twitter"
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="currentColor"
                    d="M20 3.895a8.474 8.474 0 01-2.034 2.084c0 .055.007.142.021.26.014.118.02.205.02.261 0 1.083-.162 2.167-.487 3.25a11.413 11.413 0 01-1.483 3.105c-.663.986-1.45 1.86-2.362 2.624-.912.765-2.006 1.376-3.284 1.834-1.277.458-2.65.687-4.12.687-2.288 0-4.379-.604-6.271-1.813.396.028.72.042.974.042 1.907 0 3.61-.57 5.107-1.709a3.991 3.991 0 01-2.384-.801 4.139 4.139 0 01-1.472-2.01c.24.056.501.083.784.083.381 0 .741-.055 1.08-.166a4.066 4.066 0 01-2.352-1.396 3.825 3.825 0 01-.932-2.542v-.042c.536.278 1.15.431 1.843.458a3.956 3.956 0 01-1.313-1.427A3.904 3.904 0 01.847 4.77c0-.695.177-1.375.53-2.042a11.255 11.255 0 003.739 2.99c1.462.73 3.047 1.135 4.756 1.219a3.395 3.395 0 01-.15-.918c0-1.11.403-2.059 1.208-2.843C11.735 2.392 12.716 2 13.875 2c.565 0 1.108.111 1.631.333.523.223.968.535 1.335.938.932-.18 1.801-.507 2.606-.98-.297.945-.897 1.688-1.801 2.23a8.706 8.706 0 002.352-.625L20 3.895z"
                  ></path>
                </svg>
                <span class="share-buttons__share-text">Twitter</span>
              </a>
              <a href="" class="share-buttons__facebook" @click="facebookShare()">
                <svg
                  class="icon-facebook"
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 9 20"
                >
                  <path
                    fill="currentColor"
                    d="M8.3 6.309H5.533V4.43c0-.691.494-.89.79-.89H8.3V.38H5.533C2.47.38 1.78 2.751 1.78 4.234v2.075H0v3.26h1.779v9.19h3.754v-9.19h2.47L8.3 6.31z"
                  ></path>
                </svg>
                <span class="share-buttons__share-text">facebook</span>
              </a>
            </div>
          </div-->
          <div class="product__callouts-items">
            <div class="product__callouts-item">
              <!-- <span class="icon">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.931 16.842H2.823V5.968h11.613v10.874H8.388" stroke="currentColor" stroke-width="1.05" stroke-miterlimit="10"></path>
                  <path d="M6.677 18.935a1.746 1.746 0 1 0 0-3.492 1.746 1.746 0 0 0 0 3.492z" stroke="currentColor" stroke-width=".987" stroke-miterlimit="10"></path>
                  <path d="M19.753 16.842h1.895v-6.66L19.25 7.786h-4.814v9.057h1.86" stroke="currentColor" stroke-width="1.05" stroke-miterlimit="10"></path>
                  <path d="M19.517 12.887h-2.956V9.852h1.91l1.046 1.046v1.99zM5.291 11.405h5.92M9.543 13.588l2.42-2.183-2.42-2.183M18.042 18.935a1.746 1.746 0 1 0 0-3.492 1.746 1.746 0 0 0 0 3.492z" stroke="currentColor" stroke-width=".987" stroke-miterlimit="10"></path>
                </svg>
              </span> -->
              <p v-if="productInfo.shippingFree && productInfo.shippingFree > 0">🚀 Free shipping on orders over <CurrencySymbol :symbol="productInfo.symbol"/>{{productInfo.shippingFree | moneyFormat}}</p>
              <p v-else>🚀 Free shipping and tax exemption</p>
            </div>
            <div class="product__callouts-item">
              <!-- <span class="icon">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.324 10.735h11.352M8.014 7.759h7.972M8.014 13.71h7.972" stroke="currentColor" stroke-width="1.05" stroke-miterlimit="10"></path>
                  <path d="M12 3.304c-5.32 0-9.633 3.327-9.633 7.431 0 2.625 1.769 4.927 4.433 6.249l-.808 3.392 4.739-2.282c.416.042.838.072 1.269.072 5.32 0 9.633-3.327 9.633-7.431S17.32 3.304 12 3.304z" stroke="currentColor" stroke-width="1.05" stroke-miterlimit="10" stroke-linecap="round"></path>
                </svg>
              </span> -->
              <p>🕒 Delivery within 10-15 working days</p>
            </div>
            <div class="product__callouts-item">
              <!-- <span class="icon">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.353 16.126h-5.642V14.83l-2.968 2.899 2.968 2.899v-1.295h3.79l2.718-4.696-2.35-4.068-2.779 1.604 2.209 3.824M4.634 17.845l2.821-4.887 1.121.648L7.55 9.585l-3.995 1.122 1.122.647-1.896 3.282 2.709 4.702 4.698-.001v-3.208l-4.416.001M11.006 3.376l2.821 4.887-1.121.647 3.995 1.121 1.026-4.02-1.121.648-1.895-3.283-5.426-.005-2.348 4.07 2.778 1.603 2.207-3.824" stroke="currentColor" stroke-width=".872" stroke-miterlimit="10"></path>
                </svg>
              </span> -->
              <p>💰 Within 30 days for an exchange</p>
            </div>
            <div class="product__callouts-item">
              <!-- <span class="icon">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.937 8.881H5.161v8.35h1.776v-8.35zM18.839 8.881h-1.776v8.35h1.776v-8.35zM13.349 14.62v2.611h1.776v-3.589M8.875 13.642v3.59h1.776v-2.613M20.73 18.959H3.269v1.728H20.73v-1.728z" stroke="currentColor" stroke-width=".882" stroke-miterlimit="10"></path>
                  <path d="M19.354 17.231H4.647v1.728h14.707v-1.728zM7.39 8.88H4.647V7.154h-1.42L12 2.59l8.774 4.562h-1.42V8.88H16.61M15.767 7.153h3.586" stroke="currentColor" stroke-width=".882" stroke-miterlimit="10"></path>
                  <path d="M4.647 7.153h3.586" stroke="currentColor" stroke-width=".633" stroke-miterlimit="10"></path>
                  <path d="M12 14.814A4.757 4.757 0 1 0 12 5.3a4.757 4.757 0 0 0 0 9.514z" stroke="currentColor" stroke-width=".882" stroke-miterlimit="10"></path>
                  <path d="M13.025 8.391c-.205-.173-.51-.436-.99-.436-.478 0-.976.304-.976.907s.552.83.977 1.021c.425.191 1.116.415 1.116 1.198 0 .685-.515 1.073-1.116 1.073a2.15 2.15 0 0 1-1.188-.394" stroke="currentColor" stroke-width=".83" stroke-miterlimit="10"></path>
                  <path d="M12.036 6.5v1.455M12.036 12.155V13.5" stroke="currentColor" stroke-width=".594" stroke-miterlimit="10"></path>
                </svg>
              </span> -->
              <p>🔒 Pay with Paypal, 100% safe</p>
            </div>
          </div>
        </div>
      </common-affix>

      </el-col>
    </el-row>
<!--  </el-scrollbar>-->


  <el-drawer
    :title="currentTab"
    :size="isPc ? '40%' : '85%'"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
  >

    <div class="drawer-content">

      <div>

        <div v-if="currentTab === 'Introduction' && productInfo.intro" class="product-rich-text" style="padding:0 10px" v-html="productInfo.intro.replace(/\n/g, '<br>')"></div>
        <!--<div v-if="currentTab === 'Introduction'" class="product-rich-text" style="padding:0 10px" v-html="productInfo.detail"></div>-->

        <div v-if="currentTab === 'Questions'">
          <ul style="padding:0 10px">
            <li class="question-item">
              <div class="question">Q: Where is my order confirmation?</div>
              <div class="answer">
                A: Once you place an order with us online you will receive
                an automatic order notification. Sometimes email providers
                can filter these images into the spam folder - we recommend
                taking a look there and marking the email as "not spam" so
                you receive future notifications regarding your order.
              </div>
            </li>
            <li class="question-item">
              <div class="question">Q: When will my order ship?</div>
              <div class="answer">
                A: Orders ship Monday through Friday within 24-48 hours of
                being received. Delays can happen if we are experiencing
                larger than normal sales volumes although we try to keep
                these delays to a minimum.
              </div>
            </li>
            <li class="question-item">
              <div class="question">Q: How do I track my order?</div>
              <div class="answer">
                A: Tracking information on your order will become available
                1–5 business days after it has been placed, depending on the
                shipping method selected at checkout. You will receive an
                email to notify you once your order has been shipped with a
                link to track your order through the carrier. Please note
                tracking links can take 24 hours to update with an estimated
                delivery date from the time they are sent.
                <p>
                  International orders placed with Standard Shipping may be
                  passed between local mail carriers. This means you may
                  receive more than one tracking number. If you are unsure
                  about the tracking number for your order or have a
                  question about its whereabouts, please send an email to
                  <a
                    href="mailto:customerservice@afaler.com"
                    class="cursor-pointer text-underline"
                  >customerservice@afaler.com</a
                  >. We will be happy to assist you.
                </p>
              </div>
            </li>
            <li class="question-item">
              <div class="question">Q: Where is my order?</div>
              <div class="answer">
                A: Orders placed with us typically are processed within 1-2
                business days before being shipped. You can track your order
                through the Shipment Notification email sent to your inbox.
              </div>
            </li>
            <li class="question-item">
              <div class="question">
                Q: My order arrived damaged - what do I do?
              </div>
              <div class="answer">
                A: Unfortunately damages can sometimes incur in transit
                despite our best packing efforts. If your order has arrived
                damaged please email us within 48 hours of delivery with
                your order number and include photos of the damaged item to
                <a
                  href="mailto:customerservice@afaler.com"
                  class="cursor-pointer text-underline"
                >customerservice@afaler.com</a
                >
              </div>
            </li>
            <li class="question-item">
              <div class="question">
                Q: Is my order packaging recyclable?
              </div>
              <div class="answer">
                A: Many of the items we carry come in their own branded
                packaging which we cannot control, however when packing our
                online orders we only use recyclable materials in our
                shipments. The cardboard, packing paper and paper tape can
                be recycled while our packing peanuts are 100% Biodegradable
                and can be composted accordingly.
              </div>
            </li>
            <li class="question-item">
              <div class="question">Q: How do I contact you?</div>
              <div class="answer">
                A: If you can't find the answer to your problem above and
                you still need to contact us, please contact us via email
                and include your full name, order number and photographs if
                necessary for us to quickly solve your issue. Please send
                that information to
                <a
                  href="mailto:customerservice@afaler.com"
                  class="cursor-pointer text-underline"
                >customerservice@afaler.com</a
                >
                and we will be in touch within 24 hours.
              </div>
            </li>
          </ul>
        </div>
        <div v-if="currentTab === 'Claim Your Coupons'" v-loading="loading">
          <div class="review__content" v-show="coupons.length === 0">
            Sorry, no coupons are available!
          </div>
          <div class="coupon-item" v-for="couponInfo in coupons" :key="couponInfo.id">
            <div class="row justify-content-between gx-5 gy-5">
              <div class="col-md-6 col-12">
                <div class="category__item pr">
                  <img :src="couponInfo.image" class="section-up-pic"/>
                </div>
                <h5>{{ couponInfo.couponName }}</h5>
              </div>
            </div>
            <div class="sub-info sub-content mg-t-20">
              {{ couponInfo.intro }}
            </div>
            <div class="sub-info sub-content mg-t-20">
              Discount Amount: {{ couponInfo.discount }}
            </div>
            <div class="sub-info sub-content mg-t-20">
              Usage Restrictions: {{ couponInfo.condition }}
            </div>
            <div class="sub-info sub-content mg-t-20">
              Applicable Items: {{ couponInfo.usageLimitation }}
            </div>
            <div class="sub-info sub-content mg-t-20">
              Max Claims: {{ couponInfo.limitUseNum }}&nbsp;Times(Used {{ couponInfo.usedNum }}&nbsp;Times)
            </div>
            <div class="sub-info sub-content mg-t-20" v-if="couponInfo.endTime">
              Expiration Date: {{ couponInfo.endTime }}
            </div>
            <div class="sub-info sub-content mg-t-20">
              Owned Quantity: {{ couponInfo.ownedQuantity }}&nbsp;PCS
            </div>
            <div class="btn-div">
              <button class="customer-btn" v-if="couponInfo.canClaim" @click="claim(couponInfo.code)">
                Claim Coupon
              </button>
            </div>
          </div>
        </div>
        <div v-if="currentTab === 'Reviews'">
          <div class="review__actions-write" v-show="currentTab === 'Reviews'">
            <a href="javascript:void(0);" @click="dialogVisible = true">Write a review</a>
          </div>
          <div class="review__content" v-show="comments.length === 0">
            No Data!
          </div>
          <div class="review__content data">
            <div v-if="!isThin">
              <div
                class="review-item"
                v-for="item in comments"
                :key="item.id"
              >
                <div class="review-content-wrap">
                  <div class="review-item__user">
                    <star-rating
                      v-model="item.score"
                      :show-rating="false"
                      :increment="0.5"
                      :star-size="18"
                      readOnly
                      class="review-item__star"
                    ></star-rating>
                    <p class="review-item__name comment_portfile comment_header">
                      <img :src="item.memberHeaderImg || require('@/assets/icon/head.jpg')" alt="member profile" style="margin-right: 4px;" />
                      {{ item.memberName }}
                    </p>

                    <div class="review-item__date">{{ item.createTime }}</div>

                    <p class="review-item__verified">
                      <span class="review-item__verified-dot">&nbsp;</span>
                      <span>Verified Buyer</span>
                      <span class="review-item__verified-tip ">
                          <span class="al__title"
                          >What is a verified buyer?</span
                          >
                          <span class="al_legend"
                          >A Verified Buyer is a user who has purchased the
                            reviewed product through our store.</span
                          >
                        </span>
                    </p>
                    <p class="my-rating" data-initial-rate="1.5"></p>
                  </div>
                  <div class="review-item__content">
                    <div class="review-item__content-title">
                      {{ item.title }}
                    </div>
                    <p class="review-item__content-desc">
                      {{ item.content }}
                    </p>
                  </div>

                </div>

                <div class="review-images thumbnails">
                  <div v-for="(image, index) in item.images"
                    :key="image.id"
                    :class="['thumbnail-image']"
                    @click="clickSmallImage(image, index, item.images)"
                  >
                    <img :src="image" />
                  </div>
                </div>
              </div>

            </div>
            <div v-else>
              <div v-for="item in comments" :key="item.id" style="border-bottom:1px solid #dfe3ea;padding-bottom:20px;margin-top:20px">

                <div class="flex-space-between-center mb-2">
                  <div class="comment_portfile comment_header">
                    <img :src="item.memberHeaderImg || require('@/assets/icon/head.jpg')"
                         alt="member profile" style="margin-right: 4px;"/>{{ item.memberName }}
                  </div>
                  <div class="review-item__verified">
                    <span class="review-item__verified-dot">&nbsp;</span>
                    <span>Verified Buyer</span>
                  </div>
                </div>

                <div class="flex-space-between-center">
                  <div>
                    <star-rating
                      v-model="item.score"
                      :increment="0.5"
                      :show-rating="false"
                      :star-size="18"
                      readOnly
                    ></star-rating>
                  </div>
                  <div class="review-item__date">
                    {{ item.createTime }}
                  </div>
                </div>
                <div class="review-item__content-title text-left">
                  {{ item.title }}
                </div>
                <div class="review-item__content-desc text-left">
                  {{ item.content }}
                </div>


                <div class="review-images thumbnails">
                  <div v-for="(image, index) in item.images"
                       :key="image.id"
                       :class="['thumbnail-image']"
                       @click="clickSmallImage(image, index, item.images)"
                  >
                    <img :src="image" />
                  </div>
                </div>
              </div>
            </div>
            <el-pagination
              v-if="total !== 0"
              @current-change="handleCurrentChange"
              layout="prev, pager, next"
              :current-page.sync="page"
              :page-size="limit"
              :total="total"
              style="margin-top:20px"
            >
            </el-pagination>
          </div>
          <div id="reviewPagination"></div>
        </div>
      </div>
    </div>

  </el-drawer>

  <el-dialog title="Add a Review" :visible.sync="dialogVisible" top="10%" width="80%" @close="closeReview">
    <div class="review-dialog">
      <el-form
        label-position="top"
        :model="reviewInfo"
        :rules="reviewInfoRules"
        ref="reviewInfo"
      >
        <el-form-item label="Title" prop="title">
          <el-input type="input" v-model="reviewInfo.title"></el-input>
        </el-form-item>
        <el-form-item label="Score" prop="score">
          <div class="d-flex">
            <star-rating
              v-model="reviewInfo.score"
              :show-rating="false"
              :increment="0.5"
              :star-size="18"
            ></star-rating>
          </div>
        </el-form-item>
        <el-form-item label="Content" prop="content">
          <el-input type="textarea" rows="5" v-model="reviewInfo.content"></el-input>
        </el-form-item>

        <el-form-item label="Images" prop="images">
          <BaseUploadImage :list.sync="reviewInfo.images"></BaseUploadImage>
        </el-form-item>
      </el-form>
      <button class="customer-button__submit" @click="saveReview()">
        save
      </button>
    </div>
  </el-dialog>

  <el-image-viewer v-if="viewImgVisible" :on-close="closeImgViewer" :url-list="viewImgList" :initialIndex="viewIndex" :zIndex="5000"/>
</div>
</template>
<script>
import service from '@/utils/request'
import Carousel from '@/components/Carousel'
import ColumnsSwiper from './ColumnsSwiper'
import BaseUploadImage from '../BaseUploadImage/index'
import CommonAffix from './common-affix'
import PicturePreviewVue from 'picture-preview-vue'

import StarRating from 'vue-star-rating'
export default {
  name: 'ProductOrder',
  components: {
    Carousel, StarRating, ColumnsSwiper, BaseUploadImage,
    CommonAffix,
  },
  props: {
    productInfo: {
      type: Object,
      default: () => {},
    },
    showOff: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeImage: null,
      productCount: 1,
      images: [],
      checkedAttr: {},
      showSellingPrice: '',
      attrName: '',
      flagTag: false,
      // id:'',
      title:'',
      drawer: false,
      direction: 'rtl',
      activeNames: '',
      currentTab: 'Introduction',
      isPc: true,
      comments: [],
      coupons: [],
      total:0,
      page: 1,
      limit: 10,
      dialogVisible: false,
      reviewInfo: {
        images: []
      },
      reviewInfoRules: {
        title: {
          required: true,
          trigger: 'blur',
          message: 'Title is required',
        },
        content: {
          required: true,
          trigger: 'blur',
          message: 'Content is required',
        },
      },
      viewImgVisible: false,
      viewImgList: [],
      viewIndex: 0,
      loading: false,
    }
  },
  mounted() {
    this.isPc = this.$utils.isPc();
    //this.getProductComments();
  },
  computed: {
    isThin() {
      const clientWidth = document.body.clientWidth
      return clientWidth <= 576
    },
    checkedAllAttrs() {
      // console.log(this.productInfo)
      // console.log(this.checkedAttr)
      if (
        JSON.stringify(this.checkedAttr) !== '{}' &&
        Object.keys(this.checkedAttr).length === this.productInfo.attrs.length
      ) {
        let sizeColor = JSON.stringify(this.checkedAttr)
          .replace(/{/g, '')
          .replace(/}/g, '')
          .replace(/"/g, '')
        sizeColor = sizeColor.split(',').sort().join()
        const val = this.productInfo.skus.find((el) => el.value == sizeColor)
        //console.log(val.totalCount)
        if (val.enable == '1') {
          // 不可出售
          return false
        } else if (this.productInfo.oversold === '1' && val.totalCount === 0) {
          return false
        }
      }
      // console.log(this.checkedAttr)
      if (
        this.productInfo &&
        this.productInfo.oversold === '1' &&
        this.productInfo.totalCount === 0
      ) {
        return false
      }
      if (
        this.productInfo &&
        (!this.productInfo.attrs || this.productInfo.attrs.length === 0)
      ) {
        return true
      }
      return (
        Object.keys(this.checkedAttr).length === this.productInfo.attrs.length
      )
    },
  },
  watch: {
    productInfo(val) {
      this.attrName = ''

      if (val && val.carouselImgs) {
        // console.log('productInfo changed', val)
        this.productCount = 1
        this.checkedAttr = {}
        this.showSellingPrice = val.sellingPrice
        let imageList = val.carouselImgs.split(';')
        this.images = []
        imageList.forEach((item, index) => {
          let appendix = item.lastIndexOf('.')
          let thumb = item.substring(0, appendix) + '_thumbnail.jpg'
          this.images.push({
            id: index,
            big: item,
            thumb,
          })
        })
        //console.log( this.images)

      }

      if (val && val.id) {
        this.isPc = this.$utils.isPc();
        if (this.isPc) {
          this.$refs.AffixRef.bindListener();
        }

        // 页面变化
        this.getProductComments();
        this.getCoupons();
      }
    },

  },
  methods: {
    closeImgViewer() {
      this.viewImgVisible = false;
    },
    clickSmallImage(imgUrl, index, list) {

      this.isPc = this.$utils.isPc();
      this.viewImgList = list.map(item => item.replace('_thumbnail', ''));
      this.viewIndex = index;

      if (this.isPc) {
        this.viewImgVisible = true
      } else {
        // 预览多张图片
        PicturePreviewVue({
          url: imgUrl.replace('_thumbnail', ''),
          urlList: this.viewImgList // 当前图片所在列表
        })
      }

    },
    saveReview() {
      let productId = this.$route.query.productId
      this.$refs.reviewInfo.validate((valid) => {
        if (!valid) return
        service({
          url: `/review/save`,
          method: 'post',
          data: {
            ...this.reviewInfo,
            images: this.reviewInfo.images.map(item => item.fileUrl),
            productId,
          },
        }).then((res) => {
          if (res.code === 200) {
            // this.$message.success('success');
            this.$notify({
              title: 'Success',
              message: 'Operation success!',
              type: 'success',
            })

            this.dialogVisible = false

            this.getProductComments();
          } else {
            // this.$message.error('network error')
          }
        })
      })
    },
    closeReview() {
      this.reviewInfo = {
        images: [],
      }
    },
    getProductComments(page = 1, limit = 10) {
      let productId = this.$route.query.productId
      service({
        url: `/review/${productId}?page=${page}&limit=${limit}`,
        method: 'get',
      }).then((res) => {
        //console.log(res)
        this.total = res.count
        this.comments = res.data
        this.$nextTick(() => {
          let el = document.querySelector('.data');
          if (el) {
            el.scrollIntoView(true);
            //console.log(document.querySelector('html').scrollTop)
          }
        })
      })
    },
    getCoupons() {
      let productId = this.$route.query.productId
      service({
        url: `/coupon/available/${productId}`,
        method: 'get',
      }).then((res) => {
        this.coupons = res.data
      })
    },
    claim (code) {
      if (!code) return
      this.loading = true
      service({
        url: `/coupon/${code}`,
        method: 'get',
      })
      .then((res) => {
        if (res.code === 200) {
          this.isSuccess = true;
          if (res.data?.am) {
            localStorage.setItem('am', res.data.am)
          }
        }
        this.loading = false
        this.$message({ message: res.msg, type: 'success' });
      })
      .catch((err) => {
        this.loading = false;
        this.$message({ message: res.msg, type: 'error' });
      })
    },
    handleCurrentChange(page) {
      this.page = page
      //console.log(page)
      this.getProductComments(this.page)
      //document.getElementById('comments').scrollIntoView()
    },
    toMessage(type) {
      this.currentTab = type;

      this.drawer = true;
    },
    handleClose() {
      this.drawer = false;
    },
    handleAttrClick(attrItem, item, index) {

      let detailCode = item.detailCode
      let attrName = attrItem.attrCode

      //console.log(attrName)
      //console.log(item)
      //console.log(this.productInfo)

      this.attrName = attrName
      if (item.detailImg) this.$set(attrItem, '_curr', item.detailName);

      this.$set(this.checkedAttr, attrName, detailCode)
      this.getSkuName(attrName, index)

      if (index >= 0) {
        let index_1 = this.images.findIndex(img => img.id === ('style_' + index));
        if (index_1 > 0) {
          this.activeImage = index_1;
        }
      }
    },
    getSkuName(attrName, index) {
      // if (!this.checkedAllAttrs) return
      const sortedAttrs = Object.keys(this.checkedAttr).sort()
      const skuNameArr = []
      sortedAttrs.forEach((item) => {
        skuNameArr.push(item + ':' + this.checkedAttr[item])
      })
      const skuValue = skuNameArr.join(',')
      const matchedSku = this.productInfo.skus.find(
        (item) => item.value === skuValue
      )

      if (
        matchedSku &&
        matchedSku.img &&
        this.images &&
        this.images.length > 0
      ) {
        //console.log(this.productInfo)
        // this.images[0].big = matchedSku.img
      } else {
        //console.log(456)
        // this.images[0].big = this.productInfo.carouselImgs.split(';')[0]
      }
      //console.log(index)
      if (index >= 0) {
        //console.log(this.productInfo.attrs)
        const attrs = this.productInfo.attrs
        const checkVal = attrs.find((el) => el.attrCode == attrName).details
        //console.log(checkVal)
        this.flagTag = true

        let item = {
          id: 'style_' + index,
          big: checkVal[index].detailImg.replace('_thumbnail', ''),
          thumb: checkVal[index].detailImg,
        }

        if (typeof this.images[0].id === 'number') {
          this.images.unshift(item);
        } else {
          // this.$set(this.images, '0', item);
          this.images.splice(0, 1, item)
        }

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'//平滑滚动  auto 立即跳转
        });

        // this.images[0].big = checkVal[index].detailImg.replace('_thumbnail', '')
      }
      //console.log(this.images)
      if (matchedSku && matchedSku.price) {
        this.showSellingPrice = matchedSku.price
      } else {
        this.showSellingPrice = this.productInfo.sellingPrice
      }
      this.sku = matchedSku
    },
    addToCart() {
      if (!this.productCount) {
        this.$notify({
          title: 'Tip',
          message: 'Quantity must greater than 0!',
          type: 'warning',
        })
        return
      }
      if (this.productInfo.attrs) {
        for (let attrItem of this.productInfo.attrs) {
          if (!this.checkedAttr[attrItem.attrCode]) {
            this.$notify({
              title: 'Tip',
              message: `You haven't choose ${attrItem.attrCode}!`,
              type: 'warning',
            })
            return
          }
        }
      }
      let attrList = []
      for (let [key, value] of Object.entries(this.checkedAttr)) {
        attrList.push({ attrCode: key, attrValue: value })
      }
      service({
        url: `/car/add`,
        method: 'post',
        data: {
          productId: this.productInfo.id,
          productCount: this.productCount,
          attrList,
        },
      }).then((res) => {
        if (res.data?.am) {
          localStorage.setItem('am', res.data.am)
        }
        this.$emit('addToCartSuccess')
        this.$store.dispatch('cart/setHasNewTip', true)
        fbq('track', 'AddToCart');
      })
    },
    quickOrder() {
      if (!this.productCount) {
        this.$notify({
          title: 'Tip',
          message: 'Quantity must greater than 0!',
          type: 'warning',
        })
        return
      }
      if (this.productInfo.attrs) {
        for (let attrItem of this.productInfo.attrs) {
          if (!this.checkedAttr[attrItem.attrCode]) {
            this.$notify({
              title: 'Tip',
              message: `You haven't choose ${attrItem.attrCode}!`,
              type: 'warning',
            })
            return
          }
        }
      }
      // console.log('showSellingPrice=', this.showSellingPrice)
      // console.log('productCount=', this.productCount)
      // return
      // console.log({
      //   productId: this.productInfo.id,
      //   productCount: this.productCount,
      //   sellingPrice: this.showSellingPrice,
      //   checkedAttr: JSON.stringify(this.checkedAttr),
      // })
      this.$router.push({
        name: 'Checkout',
        query: {
          productId: this.productInfo.id,
          productCount: this.productCount,
          sellingPrice: this.showSellingPrice,
          checkedAttr: JSON.stringify(this.checkedAttr),
          imagesFlag: this.flagTag ? this.images[0].big : '',
        },
        params: {
          productId: this.productInfo.id,
          skuCode: this.sku?.skuCode,
          checkedAttr: this.checkedAttr,
          productCount: this.productCount,
          sellingPrice: this.showSellingPrice,
        },
      })
    },
    handleToProductDetail(data) {
      if ('ProductDetail' !== this.$route.name) {
        const { id, name } = data
        this.$router.push({
          path: '/product-detail',
          query: {
            type: 'collection',
            productId: id,
            productName: name,
          },
        })
      }
    },
    twitterShare() {
      let id = this.productInfo.id;
      let t = this.productInfo.name;
      let url = this.shareProductUrl + "/" + id
      window.open('http://twitter.com/share?url=' + encodeURIComponent(url) + '&text=' + encodeURIComponent(t), '', 'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0')
    },
    facebookShare() {
      let id = this.productInfo.id;
      let t = this.productInfo.name;
      let url = this.shareProductUrl + "/" + id;
      window.open("http://www.facebook.com/sharer.php?u="+ encodeURIComponent(url) + "&t="+ encodeURIComponent(t), "sharer","toolbar=0,status=0,width=626,height=436");
    },
  },
}
</script>
<style scoped lang="scss">

.ProductOrder {

  .scrollbar-style {
    /*height: 100%;*/
  }

  .drawer-content {
    padding: 1rem;
  }

  ::v-deep .el-drawer__header {
    text-align: left;
    font-size: 2rem;
  }

  .product-rich-text {
    text-align: left;
  }

  .thumbnails {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
  }

  .thumbnail-image {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 18%;
    padding-top: 18%;
    margin-right: 2%;

    position:relative;

    :hover > img,
    .active > img {
      opacity: 0.6;
      box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.5);
    }

    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 250ms;
    }
  }

}

.detail-list {

  .detail-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 1rem;
    min-height: 50px;
    cursor: pointer;
    border-bottom: 1px solid #f2f2f2;

  }
}

.drawer-content {
  padding: 20px;
}

.product-detail__header {
  margin: 0;
  cursor: pointer;
}
.product-detail__desc {
  margin-top: 1rem;
}
.product-detail__price,
.product-detail__comment,
.product-detail__color-pick,
.product-detail__size-pick,
.product-detail__quantity-pick {
  margin-top: 10px;
}
.product-detail__price {
  font-size: 20px;
  font-family: Frank_Ruhl_Libre;
}
.original-price {
  text-decoration: line-through;
  color: rgb(145 149 156);
  margin-left: 10px;
}
@media screen and (min-width: 576px) {

}

.product-detail__comment {
  display: flex;
  align-items: center;
}

.small-ratings {
  margin-right: 10px;
}
.small-ratings i {
  color: #cecece;
}
.rating-color {
  color: #fbc634 !important;
}

.product-detail__color-switch {
  //margin-top: 10px;
  flex-wrap: wrap;
  display: flex;
}

.color-item, .size-item {
  padding: 5px 0;
}

.color-item input[type='radio'],
.size-item input[type='radio'] {
  display: none;
}
.color-item input[type='radio'] + label:hover,
.color-item input:checked + label,
.color-item input[checked='true'] + label {
  transform: scale(1.125);
  border-color: #232c3f;
}
.imageSize:hover {
  transform: scale(1.125);
  border-color: #232c3f;
}

.image-circle {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  cursor: pointer;
  transition: all 0.2s ease-out;
  margin-right: 10px;
  text-align: center;
  //padding: 6px;
  font-size: 0;
  width: 50px;
  height: 50px;
}

.active {
  transform: scale(1.125);
  border-color: #232c3f;
}
.color-circle {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  cursor: pointer;
  transition: all 0.2s ease-out;
  margin-right: 10px;
  text-align: center;
  padding: 6px;
  font-size: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.checked-color {
  font-weight: 400;
}

.product-detail__size-switch {
  display: flex;
  flex-wrap: wrap;
}
.size-check {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  cursor: pointer;
  transition: all 0.2s ease-out;
  margin-right: 10px;
  min-width: 50px;
  text-align: center;
  padding: 3px;
}
.size-item input[type='radio'] + label:hover,
.size-item input:checked + label {
  background-color: #000;
  color: #fff;
}
.checked-size {
  font-weight: 400;
}

.quantity-input-group {
  display: flex;
  margin-top: 10px;
}
.input-group-btn {
  display: inline-block;
  background-color: #ddd;
  color: #000;
  width: 52px;
  height: 48px;
  border-radius: 0;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
.quantity-input-group input {
  margin: 0;
  width: 100px;
  text-align: center;
}

.product-detail__actions {
  margin-top: 20px;
  display: flex;
}
.product-detail__add-to-cart,
.product-detail__buy {
  display: block;
  cursor: pointer;
  transition: border-color 0.2s ease-out,
    box-shadow 225ms cubic-bezier(0.5, 0, 0.5, 1), color 0.2s ease-out;

  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.1em;
  font-size: 0.8125rem;
  padding: 1.125rem 2rem;
  line-height: 1.4;
  color: #fff;
  background-color: #232c3f;
  border: 1px solid #232c3f;
  box-shadow: inset 0 0 #29344a;

  width: 100%;
  margin-bottom: 1.125rem;
  /*border-radius: 10rem;*/
}
.product-detail__buy {
  background-color: #2f7e6d;
  border-color: #2f7e6d;
}
.product-detail__add-to-cart:hover:not(:disabled) {
  color: #fff;
  border: 1px solid #29344a;
  box-shadow: inset 0 -4rem #29344a;
}
.product-detail__add-to-cart:disabled {
  background: #58585a;
  cursor: not-allowed;
}
.product-detail__buy:disabled {
  background: #8db5ac;
  border-color: #8db5ac;
  cursor: not-allowed;
}
.product-detail__buy:hover:not(:disabled) {
  color: #fff;
  border: 1px solid #2f7e6dd4;
  box-shadow: inset 0 -4rem #19493fd4;
}
.article-page__share-text p {
  margin: 0 0 1.5rem;
}
.article-page__share {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.article-page__share-text {
  margin-right: 20px;
}
.product__callouts-items {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}
.product__callouts-item {
  display: flex;
  padding: 4px 0;
  align-items: center;
}
.product__callouts-item .icon {
    width: 28px;
    height: 28px;
    display: inline-block;
    flex-shrink: 0;
}
.product__callouts-item p {
    margin-left: 2px;
}

.share-buttons {
  display: flex;
}

.share-buttons__twitter,
.share-buttons__facebook,
.share-buttons__pinterest {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 3px;
  transition: border-color 0.2s ease-out,
    box-shadow 225ms cubic-bezier(0.5, 0, 0.5, 1), color 0.2s ease-out;

  font-style: normal;
  font-weight: 200;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 0.5rem 0.75rem;
  color: #131416;
  background-color: transparent;
  border: 1px solid rgba(19, 20, 22, 0.3);
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.share-buttons__twitter svg,
.share-buttons__facebook svg,
.share-buttons__pinterest svg {
  width: auto;
  height: 1rem;
  margin-right: 0.5rem;
}

.review-dialog {


  ::v-deep .el-form-item {
    margin-bottom: 10px;
  }
}

.review-item__verified:hover .review-item__verified-tip {
  display: block;
}
.review-item__content {
  flex: 2;
  margin-left: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
.review-item__content-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  margin-bottom: 12px;
  margin-top: 26px;
  word-break: break-word;
}
.review-item__content-desc {
  margin-bottom: 20px;
  white-space: pre-line;
}
.review-item__date {
  color: #747571;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
}

.question-item {
  border-top: 1.5px solid #e4e1db;
  padding: 30px 0;
  text-align: left;
  list-style: none;
}

.question-item .question {
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 12px;
}

.question-item .answer p {
  margin-top: 12px;
}

.question-item .answer ul {
  padding-left: 24px;
}

.question-item .answer li {
  list-style: initial;
}


.review__actions-tab span:hover,
.review__actions-tab span.cur {
  background-color: #000;
  color: #fff;
}

.review__actions-write {
  text-align: right;
  padding: 0 20px;

  a {

    text-decoration: underline;
  }
}
.coupon-item {
  border-top: 1px solid #dfe3ea;
}
.section-up-pic {
  width: 100%;
  object-fit: cover;
  margin: 5px 5px;
}

.mg-t-20 {
  //margin-top: 0.5rem;
}

.sub-info {
  text-align: left;
  word-break: break-word;
  font-size: small;
}
.btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.customer-btn {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  display: block;
  cursor: pointer;
  transition: border-color 0.2s ease-out, box-shadow 225ms cubic-bezier(0.5, 0, 0.5, 1), color 0.2s ease-out;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.1em;
  font-size: 0.75rem;
  padding: 0.75rem 0.75rem;
  line-height: 1;
  color: #fff;
  background-color: #232c3f;
  border: 1px solid #232c3f;
  box-shadow: inset 0 0 #29344a;
  width: 100%;
}
@media screen and (min-width: 1000px) {
  .sub-content {
    margin-block-start: 1rem;
  }
}
.review-item {
  padding: 30px 20px;
  border-top: 1px solid #dfe3ea;
  text-align: left;
  color: #2d2927;

  .review-content-wrap {
    width: 100%;
    display: flex;
    word-break: break-word;
  }
}

.review-item__user {
  flex: 1;
}
/*.review-item__date {*/
/*  flex: 1;*/
/*}*/
.review-item__star {
  min-height: 22px;
  margin-bottom: 10px;
}
.review-item__name {
  color: #747571;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.review-item__verified {
  position: relative;
  align-items: center;
  border: 1px solid #898786;
  border-radius: 100px;
  color: #898786;
  display: flex;
  font-size: 12px;
  line-height: 16px;
  padding: 3px 10px;
  width: 7rem;
}
.review-item__verified-dot {
  background-color: #26c653;
  border-radius: 100%;
  display: block;
  height: 12px;
  margin-right: 5px;
  width: 12px;
}
.review-item__verified-tip {
  display: none;
  position: absolute;
  z-index: 3;
  // margin-top: 112px;
  top:28px;left: 0;background:#f1f1f1;
  border: 1px solid #000;
  padding: 10px;
}


.customer-form {
  margin-top: 1rem;
}

.customer-form-group {
  text-align: left;
}

.customer-button__submit {
  text-decoration: none;
  cursor: pointer;
  border-radius: 3px;
  transition: border-color 0.2s ease-out,
  box-shadow 225ms cubic-bezier(0.5, 0, 0.5, 1), color 0.2s ease-out;

  font-style: normal;
  font-weight: 200;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.8125rem;
  padding: 1.125rem 2rem;
  line-height: 1.4;
  color: #fff;
  background-color: #232c3f;
  border: 1px solid #232c3f;
  box-shadow: inset 0 0 #29344a;
  display: block;
  width: 100%;
  margin-bottom: 1.125rem;
  margin-top: 1rem;
}
.customer-button__submit:hover:not(:disabled),
.customer-button__submit:active {
  color: #fff;
  border: 1px solid #29344a;
  box-shadow: inset 0 -4rem #29344a;
}

// ::v-deep .vue-star-rating-rating-text {
//   padding-bottom: 8px;
// }

@media only screen and (min-width: 770px) {
  .review-item {
    justify-content: space-between;
    padding: 50px 0;
  }
  .review-item__name {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
  }
  .review-item__verified {
    cursor: pointer;
    margin-top: 15px;
    position: relative;
  }
  .review-item__content {
    font-size: 16px;
    line-height: 34px;
  }
  .review-item__content-title {
    font-size: 16px;
    line-height: 24px;
  }
  .review-item__date {
    font-size: 14px;
    line-height: 18px;
    width: 100%;
  }
}
@media only screen and (min-width: 769px) {
  .review-item__star {
    margin-bottom: 20px;
  }
  .review-item__content-title {
    margin-bottom: 14px;
    margin-top: 0;
  }
}

.product-detail {
  text-align: left;
  padding: 20px 20px 0;
}

@media screen and (max-width: 576px){
  .cur-price{
    font-size: 16px;
  }

  .scrollbar-style {

    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden!important;
    }
  }
}


@media screen and (min-width: 720px){

  body {
    position: relative;
  }

  .scrollbar-style {
    height: calc(100vh) ;

    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden!important;
    }
  }

  .scroll-row {
    display: flex;
    min-height: 100vh;

    .right-part{
      height: 100vh;
      display: flex;
      align-items: center;
    }
  }

  body {
    height: 100vh;
    overflow-y: auto;
  }
  .product-detail {
    /*position: fixed;*/
    /*top: 0;*/
    /*left: 50%;*/

    /*padding-left: 20px;*/
    padding-top: 90px;
    box-sizing: border-box;
  }
}

</style>
